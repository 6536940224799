import React from 'react';
import { Link as GatsbyLink } from "gatsby";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import homePageStyles from '../home.module.css';
import sectionStyles from './index.module.css';

import m3Monitor from '../../../../static/assets/img/m3-monitor.png';

class M3Section extends React.Component {
  render() {
    return (
      <section className={`${homePageStyles.m3Section} section`}>
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item xs={false} lg={2} xl={2} />
          <Grid item xs={12} lg={8} xl={8}>
            <div className={`sectionTitle`}>About Our Solutions</div>
            <div className={`sectionTitleUnderline ul-center`} />
            <div className={`articleBigTitle`}>
              Software Solutions
            </div>
            <div className={`${sectionStyles.articleTitle} articleTitle`}>
              M3 Mimosa Membership Management Software
            </div>
            <div>
              <p className={`${sectionStyles.generalArticle} generalArticle`}>
                Billing and Membership Management Made Easy. Our software developers worked closely with gym and martial art club owners so that we could build the best software to help you manage your memberships better, save time and money, increase cash flow and minimize paperwork. The difference between our software and others out there is in the details. We focus on providing the features and information you need most while making our platform easy to use, because billing and managing memberships shouldn't be the hard part of running your business.
              </p>
            </div>
            <div>
              <img className={`${sectionStyles.m3MonitorImg}`} src={m3Monitor} alt="" />
            </div>
            <Button variant="contained" className="m-top-20 m-bottom-20" component={GatsbyLink} to="/m3/">
              Learn More
            </Button>
          </Grid>
          <Grid item xs={false} lg={2} xl={2} />
        </Grid>
      </section>
    );
  }
}

export default M3Section;