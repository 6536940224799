import React from 'react';
import Grid from '@material-ui/core/Grid';
import homePageStyles from '../home.module.css';
import sectionStyles from './index.module.css';

class AboutSection extends React.Component {
  render() {
    return (
      <section className={`${homePageStyles.aboutSection} section`}>
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item xs={12} lg={8}>
            <div className={`${sectionStyles.aboutUsArticleContainer}`}>
              <div className={`sectionTitle`}>About Us</div>
              <div className={`sectionTitleUnderline`} />
              <div className={`articleBigTitle`}>
                What We Do
              </div>
              <p className={`generalArticle`}>
                Mimosa Solutions is a Canadian software development company with a skilled professional team, who know how to transfer business issues into software solutions. Our mission is to provide our clients with industry-leading products and technology-driven solutions to achieve greater productivity, increased revenue opportunities and quality customer service.
              </p>
            </div>
          </Grid>
          <Grid item xs={false} lg={4} />
        </Grid>
      </section>
    );
  }
}

export default AboutSection;