import React from 'react';
import Layout from '../components/Layout';
import {
  MainSection,
  AboutSection,
  M3Section
} from '../components/Home';
import ReactFullpage from '@fullpage/react-fullpage';
import { graphql } from 'gatsby';
import ContactMainSection from '../components/Contact/Main';
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const pluginWrapper = () => {
  require('fullpage.js/vendors/scrolloverflow');
};

const timeout = 1000;

class Index extends React.Component {

  render() {
    const { data } = this.props;

    return (
      <Layout location={this.props.location} title="Home">
        <head>
          <title>{data.site.siteMetadata.title}</title>
          <link rel="canonical" href={data.site.siteMetadata.url} />
        </head>
        <TransitionGroup>
          <ReactTransition
            key={''}
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
          >
            <ReactFullpage
              pluginWrapper={pluginWrapper}
              licenseKey={'AA6575F4-62CE406F-B83ACFDF-DADAA912'}
              scrollOverflow
              verticalCentered={false}
              afterRender={() => {
                window.dispatchEvent(new Event("hideHeaderBg"));
              }}
              onLeave={(index, nextIndex, direction) => {
                window.dispatchEvent(new Event(nextIndex.index ? "showHeaderBg" : "hideHeaderBg"))
              }}
              render={({ state, fullpageApi }) => {
                return (
                  <ReactFullpage.Wrapper>
                    <MainSection />
                    <AboutSection />
                    <M3Section />
                    <ContactMainSection/>
                  </ReactFullpage.Wrapper>
                );
              }}
            />
          </ReactTransition>
        </TransitionGroup>
      </Layout>
    );
  }
}

export default Index;

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
  }
`;
