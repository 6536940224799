import React from 'react';
import homePageStyles from '../home.module.css';
import sectionStyles from './index.module.css';
import Grid from '@material-ui/core/Grid';
import logoSmallIcon from '../../../../static/assets/img/logo/Logo-M-small.png'

class MainSection extends React.Component {
  render() {
    return (
      <section className={`${homePageStyles.mainSection} section`}>
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item xs={12}>
            <div className={`${sectionStyles.businessInformation}`}>
              <div className={`${sectionStyles.businessName}`}><span >Mimosa</span> Solutions</div>
              <div className={`${sectionStyles.slogan}`}>
                Innovation to run your business
              </div>
              <div className={`${sectionStyles.navigationInformation}`}>
                <div className={`${sectionStyles.horizontalLine}`} />
                <div className={`${sectionStyles.logoSmallIcon}`}><img src={logoSmallIcon} alt="" /></div>
                <div className={`${sectionStyles.beginScrolling}`}>
                  Begin Scrolling
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default MainSection;